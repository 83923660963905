import Renderer from '../..'
import { CustomSearch, CustomSearchProps } from './CustomSearch'

export default class {
  el: HTMLElement | null = null

  constructor(selector: string) {
    this.el = document.querySelector(selector)

    if (this.el) {
      this.setup()
    }
  }

  async setup() {
    const render = Renderer.getRenderer(CustomSearch)
    const props: CustomSearchProps = {
      // qs: '',
    }

    if (this.el) {
      render(props, this.el)
    }
  }
}
