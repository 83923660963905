import WebFont from 'webfontloader'

export default class WebfontLoader {
  constructor() {
    this.options = {
      custom: {
        urls: [
          // Noto Sans JP - Regular, Bold
          'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap',
          // YakuHanJP
          'https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css',
        ],
      },
      // loading: function() {},
      // active: function() {},
      // inactive: function() {},
      // fontloading: function(familyName, fvd) {},
      // fontactive: function(familyName, fvd) {},
      // fontinactive: function(familyName, fvd) {}
    }

    this.setupWebFont()
  }

  setupWebFont() {
    WebFont.load(this.options)
  }
}
