import PubSub from 'vanilla-pubsub'
import { getCookie, deleteCookie } from '../utils'

export default class ExpiredChecker {
  constructor() {
    const pathName = window.location.pathname
    const expireSoon = getCookie('mcc_pw_will_expire_soon')

    if (expireSoon && !/^\/(en\/)?mypage/.test(pathName)) {
      PubSub.publish('Modal.open')
    }

    this._bind()
  }

  _bind() {
    PubSub.subscribe('Modal.closed', this.onClosed)
  }

  onClosed() {
    deleteCookie('mcc_pw_will_expire_soon')
  }
}
