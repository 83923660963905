// import PubSub from 'vanilla-pubsub'
import clickOutside from 'click-outside'
import { wait } from '../utils'

export default class Expander {
  constructor(selector) {
    this.selector = selector
    this.el = [...document.querySelectorAll(selector)]
    this.originRegexp = new RegExp(`^${location.origin}/`)

    if (this.el.length) {
      this.bind()
      this.initialize()
    }
  }

  bind() {
    this.handleClick = this.handleClick.bind(this)
  }

  initialize() {
    this.instances = this.el
      .map((it) => {
        const toggler = it.querySelector(`${this.selector}-toggler`)
        const style = getComputedStyle(it)
        const instance = {
          el: it,
          toggler,
          open: it.dataset.expanderOpen || false,
          options: {
            submit: toggler.dataset.expanderSubmit || false,
          },
          unbind: null,
        }

        instance.duration =
          (style.transition ? parseFloat(style.transitionDuration) : 0.05) *
          1000

        toggler.$expander = instance
        toggler.addEventListener('click', this.handleClick)

        return instance
      })
      .filter(Boolean)
  }

  async handleClick(e) {
    e.preventDefault()

    const target = e.currentTarget
    const instance = target.$expander

    if (instance.open) {
      await instance.unbind()
      instance.unbind = null
    } else if (!instance.unbind) {
      instance.unbind = clickOutside(instance.el, async () => {
        await this.onClose(instance)
        instance.open = false
      })
    }

    // submit オプションが有効な場合、オープン時のクリック処理を submit に変える
    if (instance.open && instance.options.submit) {
      const form = target.form
      const isValidAction = this.originRegexp.test(form.action)

      if (isValidAction) {
        try {
          form.submit()
        } catch (err) {
          console.error(err)
        }
      }
    }

    if (instance.open) {
      await this.onClose(instance)
    } else {
      await this.onOpen(instance)
    }

    instance.open = !instance.open
  }

  async onOpen(instance) {
    const input = instance.el.querySelector('input')
    instance.el.classList.remove('is-closed')
    instance.el.classList.add('is-expanding')

    await wait(instance.duration)

    instance.el.classList.add('is-expanded')
    instance.el.classList.remove('is-expanding')
    input ? input.focus() : ''
  }

  async onClose(instance) {
    instance.el.classList.remove('is-expanded')
    instance.el.classList.add('is-closing')

    await wait(instance.duration)

    instance.el.classList.add('is-closed')
    instance.el.classList.remove('is-closing')
  }
}
