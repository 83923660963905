import { useQuery } from 'react-query'
import { QueryConfig } from '../../libs/react-query'
import { getCategories } from '../../../api/categories'

type useCategories = {
  config?: QueryConfig<typeof getCategories>
}

export const useCategories = ({ config }: useCategories) => {
  return useQuery({
    ...config,
    // queryKey: [],
    queryFn: getCategories,
  })
}

export * from '../../../api/categories/types'
