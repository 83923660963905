const locales = {
  messages: {
    en: require('./en/messages.json'),
    ja: require('./ja/messages.json'),
  },
  ui: {
    en: require('./en/ui.json'),
    ja: require('./ja/ui.json'),
  },
}

export default locales
