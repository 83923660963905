import PubSub from 'vanilla-pubsub'
import { wait } from '../utils'

export default class Modal {
  constructor(selector) {
    this.selector = selector
    this.closeButton = null
    this.el = document.querySelector(selector)

    if (this.el) {
      this._initialize()
      this._bind()
    }
  }

  _initialize() {
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.closeButton = document.querySelector(`${this.selector}-close`)
    this.closeButton.addEventListener('click', this.onClose)
  }

  _bind() {
    PubSub.subscribe('Modal.open', this.onOpen)
    PubSub.subscribe('Modal.close', this.onClose)
  }

  async onOpen() {
    this.el.setAttribute('aria-hidden', 'false')
    await wait(10)
    this.el.classList.add('-active')

    PubSub.publish('disableScroll.disable')
    PubSub.publish('Modal.opened')
  }

  async onClose() {
    this.el.classList.remove('-active')
    await wait(10)
    this.el.setAttribute('aria-hidden', 'true')

    PubSub.publish('disableScroll.release')
    PubSub.publish('Modal.closed')
  }
}
