import { useState, useRef, useEffect, useCallback } from 'react'
import PubSub from 'vanilla-pubsub'
import { useCategories, Category } from '../api/categories'
import { queryToObject } from '../../utils'
import { nonBoolean } from '../utils'
import deepmerge from 'deepmerge'
import { isSameDay } from 'react-dates'

const decodeQueryString = (str?: string) => {
  return decodeURIComponent(
    (str || '').replace('?', '').replace(/(%5B\d+%5D|\[\d+\])/g, '[]')
  )
}

export type QueryTags = string[]
export type TagQueryName = 'id' | 'name'
export type UseFilteredQueryOptionProps = {
  historyAPI?: boolean
}
export type UseFilteredQueryProps = {
  qs?: string
  options?: UseFilteredQueryOptionProps
}

export type QueryParamsProps = {
  page: string
  query: string
  tags: string[]
  [key: string]: string | string[]
}

export type ExtendQueryProps = {
  key: string
  id: string
}

export const useFilteredQuery = ({ qs, options }: UseFilteredQueryProps) => {
  const tagQueryKey: TagQueryName = 'id'
  const sanitizeRegex = /^(tags)$/
  const { historyAPI = true } = options || {}

  // propsとして受け取ったquery stringを decode
  const decodedQueryString = decodeQueryString(qs)

  // Category API with react-query
  const { isLoading, data } = useCategories({})

  // 絞り込み済ステート
  const [filtered, setFilteredState] = useState<Category[] | null>(null)

  // QueryStringをObject化したステート
  const [queryParams, setQueryParams] = useState<QueryParamsProps>(
    queryToObject(decodedQueryString, { decode: true })
  )

  // 絞り込み済ステートをQueryString化したステート
  const [queryString, setQueryString] = useState<string>(decodedQueryString)
  const [keyword, setKeyword] = useState<string>(
    String(queryParams.query || '')
  )

  // 検索欄
  const searchRef = useRef<HTMLInputElement>(null)

  // 絞り込み状態を更新
  useEffect(() => {
    if (!data) {
      return
    }

    const newFiltered = createFiltered() as Category[]

    setFilteredState(newFiltered)
  }, [isLoading, data])

  // 絞り込み状態に合わせ、QueryStringを更新
  useEffect(() => {
    const nowFiltered = filtered || createFiltered() || []

    // APIデータがない場合、フィルタできないため中断
    if (!data || !nowFiltered.length) {
      return
    }

    const filteredQuery = nowFiltered
      .reduce((acc: string[][], cur: Category) => {
        acc.push(
          (cur.items || []).map(
            (tag: Category) => `tags[]=${tag[tagQueryKey]}`
          )
        )
        return acc
      }, [])
      .flat()
      .join('&')

    const keywordQuery =
      keyword || decodeURIComponent(queryParams.query || '') || ''

    // フィルタ以外のクエリを結合
    const extendQuery: string = (
      [
        keywordQuery
          ? {
              // キーワードのパラメータ名は、query とする #426
              key: 'query',
              id: keywordQuery,
            }
          : false,
      ] as ExtendQueryProps[]
    )
      .filter(nonBoolean)
      .map((q) => `${q.key}=${q.id}`)
      .join('&')

    const newCondition = [filteredQuery, extendQuery].filter(Boolean).join('&')

    // 条件の更新があるか
    const isSameQuery = newCondition === queryString.replace(/\&?page=\d+/, '')

    // 条件に変更があったら1ページ目にリセット
    const newQuery = `${newCondition}&page=${
      isSameQuery ? queryParams.page || 1 : 1
    }`

    // クエリの状態を更新
    setQueryParams(queryToObject(`?${newQuery}`, { decode: true }))
    setQueryString(newQuery)
  }, [filtered, keyword])

  // QueryStringの更新に合わせ、履歴を更新
  useEffect(() => {
    if (historyAPI) {
      const search = decodeQueryString(location.search)

      if (filtered && search !== queryString) {
        history.replaceState({}, '', `${location.pathname}?${queryString}`)
        PubSub.publish('React.events.popState', `?${queryString}`)
      }
    }
  }, [queryString])

  // 絞り込み済の構造を作成
  const createFiltered = useCallback(() => {
    // APIデータがない場合、フィルタできないため中断
    if (!data) {
      return
    }

    // QueryStringを、状態（構造）化
    const newFiltered: Category[] = Object.keys(queryParams)
      .map((key) => {
        if (!sanitizeRegex.test(key)) {
          return false
        }
        // パラメータの値の型を確認し、いずれにしても配列とする
        const tags: QueryTags = (
          typeof queryParams[key] === 'string'
            ? [queryParams[key]]
            : queryParams[key]
        ) as string[]
        return convertTagWithCategory(tags)
      })
      .filter(nonBoolean)
      .flat()

    return newFiltered
  }, [data, queryParams])

  // QueryString は、 tags[]=tagId の形となり、keyで親階層を特定できないため、
  // /api/categories の全データから tagId の値を逆引きして親子関係を再現する
  const convertTagWithCategory = useCallback(
    (tags: QueryTags): Category[] => {
      // APIデータがない場合、フィルタできないため中断
      if (!data) {
        return []
      }

      const excludedDupTags = [...new Set(tags)]
      const tagWithCategory: Category[] = []

      excludedDupTags.forEach((tag) => {
        // タグIDから、親カテゴリを逆引き
        const category = (data || []).filter((cat) =>
          cat.items?.find((it) => it[tagQueryKey] === tag)
        )[0]

        // 不正なタグの判定
        if (!category || !category.items) return false

        // 親カテゴリの items から、現在の絞り込み状態にあるタグと一致するものを抽出してTag情報に変換
        const newItems = category.items.filter((it) => {
          return tags.find((query: string) => query === it[tagQueryKey])
        })

        // TODO: O(n^2)
        const cat = tagWithCategory.find((it) => it.id === category.id)

        if (cat) {
          cat.items = newItems
        } else {
          tagWithCategory.push({
            ...category,
            items: newItems,
          })
        }
      })

      return tagWithCategory
    },
    [data]
  )

  // categoryId を使用し、カテゴリAPIの返り値から対応する Category を返す
  const getCategoryById = (id: string): Category => {
    return (data || []).find((cat) => cat.id === id) as Category
  }

  // categoryId を使用し、絞り込み中のステートから対応する items を返す
  const getFilteredItemsById = (id: string) => {
    return ((filtered || []).find((it) => it.id === id) || {}).items || []
  }

  // キーワード検索
  const handleSearch = useCallback(
    () => (event: React.MouseEvent<HTMLElement>) => {
      setKeyword(searchRef?.current?.value || '')
    },
    []
  )

  // 絞り込み中かの判定
  const isFiltered = useCallback(
    (categoryId: string, tagId: string) => {
      return Boolean(
        [...(filtered || [])].find((cat) => {
          return (
            cat.id === categoryId &&
            (cat.items || []).find((tag) => tag.id === tagId)
          )
        })
      )
    },
    [filtered]
  )

  // 絞り込みを追加
  const handleAdd = useCallback(
    (categoryId: string) => (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLButtonElement
      const categoryData = getCategoryById(categoryId)
      const filteredItems = getFilteredItemsById(categoryId)
      const newItems = deepmerge(
        [...filteredItems],
        [
          {
            id: target.value,
            name: target.innerText,
            category: categoryId,
          },
        ]
      )
      const newData: Category[] = [
        ...(filtered || []).filter((it) => it.id !== categoryData.id),
        {
          ...(filtered?.filter((it) => it.id === categoryData.id) || {}),
          ...categoryData,
          items: newItems,
        },
      ]
      setFilteredState(newData)
    },
    [filtered]
  )

  // 絞り込み中のタグ削除
  const handleDelete = useCallback(
    () => (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLButtonElement

      const newData = [...(filtered || [])].filter((cat) => {
        if (cat.id !== target.name) {
          return cat
        }
        cat.items = (cat.items || []).filter(
          (tag) => tag.id !== target[tagQueryKey === 'id' ? 'value' : 'name']
        )
        return cat
      })
      setFilteredState(newData)
    },
    [filtered]
  )

  return {
    isLoading,
    data,
    searchRef,
    keyword,
    filtered,
    handleAdd,
    handleDelete,
    handleSearch,
    isFiltered,
    tagQueryKey,
  }
}
