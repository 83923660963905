import PubSub from 'vanilla-pubsub'
import project from '../project'

export default class TagTicker {
  constructor() {
    this.el = document.querySelector('.js-header')
    this.y = 0

    this.bind()
  }

  _initialize() {
    if (this.el) {
      this.check()
    }
  }

  check() {
    const scrollY = project.$window.scrollY
    const headerHight = this.el.clientHeight
    if(scrollY > headerHight) {
      this.fix()
    } else if(scrollY<=0) {
      this.relese()
    }
    if(scrollY < this.y && scrollY > 0) {
      this.show()
    } else {
      this.hide()
    }
    this.y = scrollY
  }

  fix() {
    this.el.classList.add('is-fixed')
  }
  relese() {
    this.el.classList.remove('is-fixed')
  }

  show() {
    this.el.classList.add('is-active')
  }
  hide() {
    this.el.classList.remove('is-active')
  }

  bind() {
    this._initialize = this._initialize.bind(this)
    this.check = this.check.bind(this)
    this.fix = this.fix.bind(this)
    this.relese = this.relese.bind(this)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    PubSub.subscribe('App.scroll', this._initialize)
  }
}
