// Libs
import PubSub from 'vanilla-pubsub'

// Utils
import { getBrowser } from './utils'

// Plugins
import WebfontLoader from './plugins/webFontLoader'

// Modules
import Viewport from './modules/Viewport'
import HashPosition from './modules/HashPosition'
import DisableScroll from './modules/DisableScroll'
import SmoothScroll from './modules/SmoothScroll'
import Header from './modules/Header'
import Nav from './modules/Nav'
import Pagetop from './modules/Pagetop'
import DetailsExtension from './modules/DetailsExtension'
import CustomSearch from '@/assets/js/react/components/CustomSearch'
import Expander from './modules/Expander'
import Modal from './modules/Modal'
import ExpiredChecker from './modules/ExpiredChecker'

// ProjectClass (singleton)
import project from './project'

// Mocks
import { initMocks } from './test/server'

initMocks()

class App {
  constructor() {
    this._initialize()

    // ページごとに実行されるjs群 （for dynamic import）
    this.pages = {
      top: 'top.js',
      archive: 'archive.js',
      post: 'post.js',
      search: 'search.js',
      mypage: 'mypage.js',
      form: 'form.js',
      contact: 'contact.js',
      mctv: 'mctv.js',
    }

    // ページ共通で実行されるjs群
    this.globals = {
      viewport: new Viewport(),
      hashPosition: new HashPosition(),
      disableScroll: new DisableScroll(),
      smoothScroll: new SmoothScroll(),
      header: new Header(),
      nav: new Nav(),
      pagetop: new Pagetop(),
      detailsExtension: new DetailsExtension('.js-detailsExtension'),
      customSearch: new CustomSearch('.js-customSearch'),
      expander: new Expander('.js-expander'),
      modal: new Modal('.js-modal'),
      expiredChecker: new ExpiredChecker(),
    }

    this._setup()
  }

  _initialize() {
    // Init webfont
    new WebfontLoader()

    // Define Browser UA
    const browser = getBrowser()
    document.documentElement.classList.add(browser)
  }

  async _setup() {
    // 現在ページを取得
    const $root = document.querySelector('.js-root')
    const current = $root.dataset.page || ''

    // 対応するDynamic Importモジュールを確認
    const page = this.pages[current] || false

    // グローバルモジュールを projectインスタンス に追加
    project.addModules(this.globals)

    // 現在ページ用のmodule郡をDynamic Importしてインスタンス化
    if (page) {
      this.currentPage = await this.importer(current)
    }

    PubSub.publish('App.ready')
    document.body.classList.add('is-app-ready')
  }

  importer(filename) {
    return (
      import(`./pages/${filename}`)
        /* eslint-disable-next-line new-cap */
        .then((Module) => new Module.default('.js-root'))
        .catch((err) => {
          console.error(err)
        })
    )
  }
}

new App()
