/**
 * CustomSearch
 * @desc 詳細検索用のコンポーネント
 * TODO: ロジック部分はほぼ、PostFilterと同様のため、共通化したい
 */

import React, { memo, useCallback } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import ReactScrollbarsCustom, {
  ScrollbarContext,
  Scrollbar,
} from 'react-scrollbars-custom'
import { Tag } from '@/assets/js/react/api/categories'
import { useFilteredQuery } from '@/assets/js/react/hooks/useFilteredQuery'
import { nonUndefined } from '@/assets/js/react/utils'
import { __ } from '@/assets/js/utils'

export type CustomSearchProps = {
  qs?: string
}

export const CustomSearch = memo((props: CustomSearchProps) => {
  const initialLimit = 4 // タグの初期表示数

  const {
    isLoading,
    data,
    searchRef,
    keyword,
    filtered,
    handleAdd,
    handleDelete,
    handleSearch,
    isFiltered,
    tagQueryKey,
  } = useFilteredQuery({ qs: props.qs, options: { historyAPI: false } })

  const wrapperProps = {
    renderer: (props: any) => {
      const { elementRef, style, ...restProps } = props
      return (
        <span
          {...restProps}
          ref={elementRef}
          style={{ ...style, right: 0, bottom: 0 }}
        />
      )
    },
  }

  const trackYProps = {
    renderer: (props: any) => {
      const { elementRef, style, ...restProps } = props

      return (
        <div
          {...restProps}
          ref={elementRef}
          style={{
            ...style,
            top: '0',
            right: '0',
            width: '10px',
            height: '100%',
            borderRadius: '0',
            backgroundColor: '#ccc',
          }}
        />
      )
    },
  }

  const scrollerProps = {
    renderer: (props: any) => {
      const { elementRef, style, ...restProps } = props

      return (
        <div
          {...restProps}
          ref={elementRef}
          style={{
            ...style,
            borderRadius: '0',
          }}
        />
      )
    },
  }

  const thumbProps = {
    renderer: (props: any) => {
      const { elementRef, style, ...restProps } = props

      return (
        <div
          {...restProps}
          ref={elementRef}
          style={{
            ...style,
            borderRadius: '0',
            backgroundColor: '#1E1E1E',
          }}
        />
      )
    },
  }

  if (isLoading) {
    return <div>{__('ui.loading')}</div>
  }

  return (
    <div className="p-customSearch">
      <ReactScrollbarsCustom
        noScrollX={true}
        wrapperProps={wrapperProps}
        trackYProps={trackYProps}
        scrollerProps={scrollerProps}
        thumbYProps={thumbProps}
      >
        <div className="p-customSearch__content">
          <h2 className="p-customSearch__heading u-sp-block">
            {__('ui.articleSearch')}
          </h2>

          <div className="p-customSearch__field">
            <h2 className="p-customSearch__title -single">
              {__('ui.saerchWithKeyword')}
            </h2>
            <div className="p-customSearch__item">
              <div className="p-customSearch__input">
                <input
                  type="text"
                  name="query"
                  defaultValue={keyword}
                  placeholder={__('ui.enterKeyword')}
                  ref={searchRef}
                />
                <button
                  className="iconfont-search"
                  onClick={handleSearch()}
                ></button>
              </div>
            </div>
          </div>
          <div className="p-customSearch__field">
            <h2 className="p-customSearch__title">
              {__('ui.searchByCondition')}
            </h2>
            <div className="p-customSearch__item">
              {data &&
                data.map((cat) => {
                  if ((cat.items || []).length > initialLimit) {
                    return (
                      <Accordion
                        key={cat.id}
                        allowZeroExpanded={true}
                        className="p-customSearch__category"
                      >
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton className="p-customSearch__categoryName">
                              {cat.name}
                            </AccordionItemButton>
                          </AccordionItemHeading>

                          {/* 初期表示 */}
                          <div className="p-tags">
                            {[...(cat.items || [])]
                              .splice(0, initialLimit)
                              .map((tag) => {
                                return (
                                  <button
                                    key={tag.id}
                                    type="button"
                                    className="c-tag"
                                    name={cat.id}
                                    value={tag.id}
                                    onClick={handleAdd(tag.category)}
                                    aria-pressed={isFiltered(cat.id, tag.id)}
                                  >
                                    {tag.name}
                                  </button>
                                )
                              })}
                          </div>

                          {/* 追加表示 */}
                          <AccordionItemPanel className="p-filter__additionalItem">
                            <div className="p-tags">
                              {[...(cat.items || [])]
                                .splice(initialLimit)
                                .map((tag) => {
                                  return (
                                    <button
                                      key={tag.id}
                                      type="button"
                                      name={cat.id}
                                      value={tag.id}
                                      className="c-tag"
                                      onClick={handleAdd(tag.category)}
                                      aria-pressed={isFiltered(cat.id, tag.id)}
                                    >
                                      {tag.name}
                                    </button>
                                  )
                                })}
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    )
                  }

                  // 項目数が、アコーディオン化の閾値を下回っている場合のDOM構成
                  return (
                    <div key={cat.id} className="p-customSearch__category">
                      <p className="p-customSearch__categoryName">
                        {cat.name}
                      </p>
                      <div className="p-tags">
                        {(cat.items || []).map((tag: Tag) => {
                          return (
                            <button
                              key={tag.id}
                              type="button"
                              name={cat.id}
                              value={tag.id}
                              className="c-tag"
                              onClick={handleAdd(tag.category)}
                              aria-pressed={isFiltered(cat.id, tag.id)}
                            >
                              {tag.name}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          {/* /.p-customSearch__field */}
        </div>
        {/* 絞り込みステータス */}
        <div className="p-customSearch__footer">
          <div className="p-tags p-customSearch__tags">
            {filtered &&
              filtered
                .map((state) => {
                  return (state.items || [])
                    .map((filter) => {
                      return (
                        <div key={filter.id}>
                          <input
                            type="hidden"
                            name="tags[]"
                            value={filter[tagQueryKey]}
                          />
                          <button
                            key={filter.id}
                            type="button"
                            name={state.id}
                            value={filter.id}
                            className="c-tag -active"
                            onClick={handleDelete()}
                          >
                            {filter.name}
                          </button>
                        </div>
                      )
                    })
                    .filter(nonUndefined)
                })
                .filter(nonUndefined)}
          </div>
          <button className="c-button">{__('ui.search')}</button>
        </div>
      </ReactScrollbarsCustom>
    </div>
  )
})
