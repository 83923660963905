import PubSub from 'vanilla-pubsub'
import { wait } from '../utils'

export default class DetailsExtension {
  constructor(selector) {
    this.el = [...document.querySelectorAll(selector)]

    if (this.el.length) {
      this.bind()
      this.initialize()
    }
  }

  bind() {
    this.handleClick = this.handleClick.bind(this)
  }

  initialize() {
    this.instances = this.el
      .map((it) => {
        const summary = it.querySelector('summary')
        const details = summary.parentElement
        const options = {
          extOpenToDisableScroll: it.dataset.extOpenToDisableScroll || false,
          extTransitionClass: it.dataset.extTransitionClass || false,
          extClose: it.dataset.extClose || '',
        }

        if (details.tagName !== 'DETAILS') {
          console.warn(
            '[DetailsExtension]',
            'The parent of summary must be details.'
          )
          return false
        }

        const instance = {
          details,
          summary,
          options,
        }

        /**
         * オプション： TransitionClass
         * @doc 子要素の transitionDuration の秒数に従い属性を付与するようになる
         */
        if (options.extTransitionClass) {
          const contents = it.querySelector('*:not(summary)')
          const style = getComputedStyle(contents)

          instance.duration =
            (style.transition ? parseFloat(style.transitionDuration) : 0.05) *
            1000
        }

        /**
         * オプション： Close
         * @doc 指定されたセレクタを持つ要素をクリック時に details を強制クローズする
         */
        if (options.extClose) {
          const buttonClose = [...it.querySelectorAll(options.extClose)]

          buttonClose.forEach((button) => {
            button.addEventListener('click', () => {
              this.onClose(instance)
            })
          })
        }

        summary.addEventListener('click', this.handleClick)
        summary.$extension = instance

        return instance
      })
      .filter(Boolean)
  }

  handleClick(e) {
    const target = e.currentTarget
    const instance = target.$extension
    const hasOpen = !(
      typeof instance.details.getAttribute('open') === 'string'
    )
    if (hasOpen) {
      this.onOpen(target.$extension)
    } else {
      this.onClose(target.$extension)
    }
  }

  async onOpen(instance) {
    if (instance.options.extOpenToDisableScroll) {
      PubSub.publish('disableScroll.disable')
    }

    if (instance.options.extTransitionClass) {
      instance.details.removeAttribute('closed')
      instance.details.setAttribute('opening', '')

      await wait(instance.duration)

      instance.details.setAttribute('opened', '')
      instance.details.removeAttribute('opening')
      instance.details.removeAttribute('close')
    }
  }

  async onClose(instance) {
    if (instance.options.extOpenToDisableScroll) {
      PubSub.publish('disableScroll.release')
    }
    if (instance.options.extTransitionClass) {
      instance.details.removeAttribute('opened')

      instance.details.setAttribute('closing', '')
      await wait(instance.duration)
      instance.details.setAttribute('closed', '')
      instance.details.removeAttribute('closing')
      instance.details.removeAttribute('open')
    }
  }
}
