import PubSub from 'vanilla-pubsub'
import project from '../project'

export default class Nav {
  constructor() {
    this.el = document.querySelector('.js-navButton')
    this.w = project.$window.width

    this.bind()
    if(this.el) {
      this._initialize()
    }
  }

  _initialize() {
    this.el.addEventListener('click', this.toggle)
  }

  toggle() {
    document.body.classList.toggle('is-navOpen')
  }

  open() {
    document.body.classList.add('is-navOpen')
  }

  close() {
    const currentWidth = project.$window.width
    if(this.w != currentWidth) {
      document.body.classList.remove('is-navOpen')
    }
    this.w = currentWidth
  }

  bind() {
    this.toggle = this.toggle.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    PubSub.subscribe('App.resize', this.close)
  }
}
