import SweetScroll from 'sweet-scroll'

/**
 * SweetScroll
 * @doc https://www.npmjs.com/package/sweet-scroll#3-initialize-sweetscroll
 */
export default class SmoothScroll {
  constructor() {
    const offset = /story/.test(location.pathname) ? -100 : -50

    this.instance = new SweetScroll({
      trigger: 'a[href^="#"]', // トリガーとなる要素をCSSセレクタで指定
      duration: 800, // アニメーション再生時間のミリ秒
      easing: 'easeOutQuint', // イージングのタイプ
      offset, // スクロール位置のオフセット
      updateURL: true,
      // header: "[data-scroll-header]", // 固定ヘッダをCSSセレクタで指定
      // delay: 0,                       // アニメーション開始までの遅延ミリ秒
      // verticalScroll: true,           // 垂直方向のスクロールを許可
      // horizontalScroll: false,        // 水平方向のスクロールを許可 (デフォルトでは無効)
      // stopScroll: true,               // ホイール・タッチイベントが発生した時にスクロールを停止

      // // Callbacks
      // beforeScroll: null,             // スクロールが始まる前 (return falseでキャンセル可)
      // afterScroll: null,              // スクロールが終わった時
      // cancelScroll: null              // スクロールがキャンセルされた時
    })
  }

  get scroller() {
    return this.instance
  }
}
